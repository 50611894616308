<template>
    <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
        <div class="change-password" v-if="setPasswordModal" slot="component">
            <p class="change-password-title">
                {{ $t("Set Password") }}
            </p>
            <form @submit.prevent="changePassword()">
                <div class="form-group">
                    <JhakaasInput
                        inputType="password"
                        :label="$t('enter new password')"
                        inputId="new-password"
                        reference="new-password"
                        :helperText="formError"
                        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
                        autocomplete="false"
                        @keydown.space.prevent
                        required
                        :value="userNewPassword"
                        @onChange="value => userNewPassword = value"
                    >
                        <template v-slot:endIcon>
                            <span class="eye">
                                <img style="width: 20px;" :src="eyeopen" @click="toggleEye($event)" alt />
                            </span>
                        </template>
                    </JhakaasInput>

                    <JhakaasInput
                        inputType="password"
                        :label="$t('confirm password')"
                        inputId="confirm-password"
                        reference="confirm-password"
                        :helperText="confirmPasswordError"
                        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
                        autocomplete="false"
                        @keydown.space.prevent
                        required
                        :value="userConfirmPassword"
                        @onChange="value => userConfirmPassword = value"
                    >
                        <template v-slot:endIcon>
                            <span class="eye">
                                <img style="width: 20px;" :src="eyeopen" @click="toggleEye($event)" alt />
                            </span>
                        </template>
                    </JhakaasInput>
                </div>

                <button class="button-primary update" type="submit" ref="update">
                    {{ $t("update") }}
                </button>
            </form>
        </div>
    </Popup>
</template>
  
<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapGetters } from "vuex";
import snackbar from "node-snackbar";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import SimpleCrypto from "simple-crypto-js";
import { _providerId } from "@/provider-config.js";
import { showSnackBar } from '../../../utilities';
import { toFormUrlEncoded } from "../../../store/helper";

export default {

    data() {
        return {
            userNewPassword: "",
            userConfirmPassword: "",
            newPasswordPlaceholder: "",
            confirmPasswordError: "",
            eyeopen: require("@/assets/icons/eye.svg"),
            eyeClose: require("@/assets/icons/eye-close.svg"),
            width: "50%",
            margin: "10% auto",
            radiusType: "full",
            formError: null,
            localDisplayLang: null,
            setPasswordModal: true
        };
    },
    computed: {
        ...mapGetters(["secret_key", "getRtl"]),
    },
    watch: {
        formError(val) {
            if (val) {
                setTimeout(() => {
                    this.formError = null;
                    this.oldPasswordError = "";
                }, 5000);
            }
        },
    },
    created() {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

        //checking for change of language in local storage value
        eventBus.$on("check-local-storage", (data) => {
            this.localDisplayLang = data;
        });
    },
    mounted() {
        this.newPasswordPlaceholder = this.$t("enter new password")

        eventBus.$on("changePasswordResponse", (response) => {
            if (response.reason) {
                // this.$refs.update.disabled = false;
                this.formError = response.reason;
                this.userNewPassword = "";
                this.userOldPassword = "";
            } else {

            let subscriberDetails = localStorage.getItem("subscriberDetails") ? JSON.parse(localStorage.getItem("subscriberDetails")) : null;
            subscriberDetails.data.setpassword = "YES";

            localStorage.setItem("subscriberDetails", JSON.stringify(subscriberDetails));
            
            showSnackBar(this.$t("Password Changed Successfully!"), this.$t('DISMISS'));

            this.closePopup();
            }
        });
    },
    methods: {
        closePopup() {
            this.$emit("close-use-password", false);
        },
        changePassword() {
            if (!this.validatePassword() || !this.validateConfirmPassword()) return;
            if (this.userNewPassword !== this.userConfirmPassword) {
                this.confirmPasswordError = this.$t("Passwords do not match");
                this.userNewPassword = "";
                this.userConfirmPassword = "";
                return;
            }
            let payload = {
                password: this.userNewPassword,
            };
            eventBus.$emit("changePassword", payload);
            // this.$refs.update.disabled = true;
        },

        validatePassword() {
            this.formError = null;
            if (!this.userNewPassword) {
                this.formError = this.$t("New Password Required!");
                return false;
            } else if (this.userNewPassword.length < 6) {
                this.formError = this.$t("Password should be minimum 6 characters");
                return false;
            } else if (this.userNewPassword.length > 16) {
                this.formError = this.$t("Password should be maximum 16 characters");
                return false;
            }

            return true;
        },
        validateConfirmPassword() {
            this.confirmPasswordError = null;
            if (!this.userConfirmPassword) {
                this.confirmPasswordError = this.$t("Confirm Password Required!");
                return false;
            } else if (this.userConfirmPassword.length < 6) {
                this.confirmPasswordError = this.$t("Password should be minimum 6 characters");
                return false;
            } else if (this.userConfirmPassword.length > 16) {
                this.confirmPasswordError = this.$t("Password should be maximum 16 characters");
                return false;
            } else if (this.userNewPassword !== this.userConfirmPassword) {
                this.confirmPasswordError = this.$t("Passwords do not match");
                return false;
            }

            return true;
        },
    },
    components: {
        Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
        JhakaasInput: () => import("@/components/utils/JhakaasInput.vue"),
    },
    mixins: [Utility],
};
</script>
  
<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./ChangePassword.scss"
</style>
  